import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import { get, noop } from "lodash";
import { RaceResult } from "@tvg/design-system/web";
import { openMybetsPastPerformance } from "@tvg/sh-lib-my-bets/redux/actions";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import {
  getHideResults,
  getCurrentReplayVideo
} from "@urp/lib-racetracks/src/redux/selectors";
import { ToggleHideResultsEnum } from "@urp/lib-racetracks/src/types";
import { getIsLogged } from "@urp/store-selectors";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { openLoginWall } from "@tvg/utils/login";
import buildRaceUrl from "@tvg/formatter/url";
import { MyBetsPastPerformanceTab } from "@tvg/sh-lib-my-bets/utils/types";
import {
  formatDateToMMDDYYYYhhmm,
  formatPastRaceDate
} from "@tvg/formatter/dates";
import { usePastRace } from "@urp/lib-racetracks";
import {
  sendReplayVideoClickedAnalyticEvt,
  sendViewResultsAnalyticEvt
} from "@urp/lib-racetracks/src/utils/analytics";
import { setCurrentReplayVideo } from "@urp/lib-racetracks/src/redux/actions/tracks";
import VideoPlayer from "@urp/video-player";

// Types
import type { Race, RaceTypeCodeEnum, Results } from "@tvg/ts-types/Race";
import type { Runner } from "@tvg/design-system/web/components/RaceResult/types";
import { PastRaceResults } from "@tvg/ts-types/Race";
import { getUserCompliance } from "@urp/unified-modules/src/utils/account/getUserCompliance";
import type { RaceResultCardProps } from "./types";

import { getPayoutHeaders } from "../../utils";
import { MAX_RUNNERS_RESULTS } from "../../constants";
import { ResultRacecardWrapper } from "./styled-components";
import { getResultRaceRunners } from "./utils";

const RaceResultCard: FC<RaceResultCardProps> = ({
  dispatch,
  race,
  loading = false,
  runnerNameHighlight,
  isGrouped = false,
  isButtonsHidden = false,
  isTrackNameShown = false,
  betsCounter,
  showFullNameOnReplayVideo = false,
  onMyBetsClick = noop
}) => {
  const cardRef = useRef<HTMLSpanElement>(null);
  const currentReplayVideo = useSelector(getCurrentReplayVideo);
  const isMobile = useMediaQuery(breakpoints.desktop.max.sm);
  const hideResults = useSelector(getHideResults);
  const [showResults, setShowResults] = useState<boolean>(
    hideResults !== ToggleHideResultsEnum.ON
  );

  const isLogged = useSelector(getIsLogged);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  const [runners, setRunners] = useState<Runner[]>([]);

  const hasRaceResults = useMemo(
    () => get(race, "results.runners")?.length > 0,
    [race]
  );

  // TODO: This hook it's gonna be removed when results info is available on list.
  const { firstPastRace, isLoading: isLoadingPastRace } = usePastRace({
    date: formatPastRaceDate(get(race, "date") ?? new Date()),
    trackCode: race.track.code,
    raceNumber: race.number,
    isEnabled: !hasRaceResults
  });

  const workingRace = hasRaceResults ? race : firstPastRace;
  const trackRace = `${race.track.code + race.number}`;
  const replayFileName = get(workingRace, "video.replayFileName");
  const raceUrl = useMemo(
    () => buildRaceUrl(race.track.code, race.track.name, +race.number),
    [race]
  );

  const checkIsLoggedIn = useCallback(
    (cb: () => void, enforceAccountCompliant = false) => {
      if (!isLogged) {
        openLoginWall(cb);
      } else if (!isAccountCompliant && enforceAccountCompliant) {
        getUserCompliance();
      } else {
        cb();
      }
    },
    [isLogged, isAccountCompliant]
  );

  useEffect(() => {
    const results: Results = get(workingRace, "results");

    if (runners?.length === 0) {
      setRunners(getResultRaceRunners(results?.runners));
    }
  }, [loading, isLoadingPastRace, workingRace]);

  useEffect(() => {
    setShowResults(hideResults !== ToggleHideResultsEnum.ON);
    dispatch(setCurrentReplayVideo(null));
  }, [hideResults]);

  const handleSeeResults = () => {
    const hasBackButton = false;
    //  If the date property is not available (current day - pastRace type Race), it falls back to using the postTime property
    const dateFilter = formatPastRaceDate(
      get(workingRace, "date") ?? workingRace?.postTime
    );

    let myBetsSelectedTab = MyBetsPastPerformanceTab.FULL_RESULT;
    if (hideResults === ToggleHideResultsEnum.ON) {
      myBetsSelectedTab = MyBetsPastPerformanceTab.RACECARD;
    }

    dispatch(
      openMybetsPastPerformance(
        {
          trackCode: workingRace.track.code,
          raceNumber: Number(workingRace.number),
          raceDate: dateFilter as string
        },
        `${workingRace.track.name} R${workingRace.number} - Replay`,
        myBetsSelectedTab,
        hasBackButton
      )
    );
  };

  const handleClickOnReplayButton = () => {
    const enforceAccountCompliant = true;
    checkIsLoggedIn(
      () =>
        dispatch(
          setCurrentReplayVideo(
            workingRace.id,
            (workingRace as PastRaceResults)?.video?.replayFileName
          )
        ),
      enforceAccountCompliant
    );
    sendReplayVideoClickedAnalyticEvt({
      trackName: race.track.name,
      raceNumber: race.number,
      linkUrl: raceUrl,
      trackCountry: (race as Race)?.track?.location?.country || "",
      hideResultsSpoiler:
        hideResults === ToggleHideResultsEnum.ON ? "on" : "off"
    });
  };

  const trackViewResultsEvt = () => {
    sendViewResultsAnalyticEvt({
      trackName: race.track.name,
      raceNumber: race.number,
      linkUrl: raceUrl,
      trackCountry: (race as Race)?.track?.location?.country || "",
      hideResultsSpoiler:
        hideResults === ToggleHideResultsEnum.ON ? "on" : "off"
    });
  };

  const showInlineReplay = workingRace?.id === currentReplayVideo && !isMobile;

  return (
    <ResultRacecardWrapper ref={cardRef}>
      {showInlineReplay ? (
        <VideoPlayer
          qaLabel="replay-video-tracks-results"
          replayFile={replayFileName}
          width={cardRef.current?.scrollWidth}
          height={cardRef.current?.scrollHeight}
          onClose={() => dispatch(setCurrentReplayVideo(null))}
          title={
            showFullNameOnReplayVideo
              ? `${race.track.code} - R${race.number}`
              : `Race ${race.number}`
          }
          isAutoPlay
        />
      ) : (
        <RaceResult
          key={trackRace}
          time={formatDateToMMDDYYYYhhmm(race.postTime)}
          raceNumber={+race.number}
          raceTypeCode={race.type.code as RaceTypeCodeEnum}
          trackName={isTrackNameShown ? race.track.name : ""}
          runnerNameHighlight={runnerNameHighlight}
          runners={runners}
          betsCounter={betsCounter}
          payoutHeaders={getPayoutHeaders(runners, MAX_RUNNERS_RESULTS)}
          showRunners={showResults}
          isReplayAvailable={Boolean(replayFileName)}
          isLoading={loading || isLoadingPastRace || !workingRace}
          isGrouped={isGrouped}
          hideButtons={isButtonsHidden}
          onSeeResultClick={() => {
            trackViewResultsEvt();
            checkIsLoggedIn(handleSeeResults);
          }}
          onRevealClick={() => {
            trackViewResultsEvt();
            setShowResults(true);
          }}
          onWatchReplayClick={handleClickOnReplayButton}
          onMyBetsClick={onMyBetsClick}
        />
      )}
    </ResultRacecardWrapper>
  );
};

export default RaceResultCard;
