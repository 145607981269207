import React, { memo, useEffect } from "react";
import { Store } from "redux";
import { Header, RaceCell } from "@tvg/design-system/web";
import { useTrackRaces } from "@urp/lib-racetracks";
import { useURPProgramPageOnXSell } from "@urp/amplitude-experiments";
import {
  getRacePromos,
  isUserOptedInPromo
} from "@urp/lib-racetracks/src/utils/races";
import { get } from "lodash";
import { getActiveBets } from "@urp/lib-racetracks/src/redux/selectors";
import buildRaceUrl from "@tvg/formatter/url";
import { useSelector } from "react-redux";
import { getIsLogged, getOptedInPromos } from "@urp/store-selectors";
import type { Props } from "./types";
import EmptyTrackRaces from "../../emptyCases/EmptyTrackRaces";
import { Container, RacesContainer } from "./styled-components";
import { sendRaceClickEvent } from "../../amplitude";

const TrackRaces = ({ track, client }: Props) => {
  const isLogged = useSelector(getIsLogged);
  const optedInPromos = useSelector(getOptedInPromos);
  const shouldUseUrpPPonXSell = useURPProgramPageOnXSell();
  const activeBets = useSelector((store: Store) =>
    getActiveBets(store, shouldUseUrpPPonXSell)
  );
  const { results, startPolling, stopPolling } = useTrackRaces(client, track);

  useEffect(() => {
    if (results.length) startPolling();

    return () => {
      stopPolling();
    };
  }, [results.length]);

  return (
    <Container>
      <Header qaLabel="" fontFamily="bold" paddingY="space-5" tag="h2">
        <>Racing at {track.name}</>
      </Header>
      {results.length ? (
        <RacesContainer data-qa-label="tracks-upcoming-races">
          {results.map((race) => {
            const availableRacePromos = getRacePromos(race);
            const isOptedIn =
              isLogged &&
              isUserOptedInPromo(optedInPromos, availableRacePromos);
            const raceUrl = buildRaceUrl(track.code, track.name, +race.number);
            return (
              <RaceCell.Summary
                key={track.code + race.number}
                race={race}
                qaLabel=""
                to={raceUrl}
                hasPromosAvailable={
                  (!isLogged || !isOptedIn) && !!availableRacePromos.length
                }
                isOptedIn={isOptedIn}
                numberActiveBets={get(activeBets, race?.tvgRaceId ?? "", 0)}
                onClick={() =>
                  sendRaceClickEvent({
                    destinationUrl: raceUrl,
                    mtp: race.mtp,
                    raceNumber: +race.raceNumber,
                    trackName: track.name
                  })
                }
              />
            );
          })}
        </RacesContainer>
      ) : (
        <EmptyTrackRaces />
      )}
    </Container>
  );
};

export default memo(TrackRaces);
