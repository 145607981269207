import React, { useMemo } from "react";
import { Icon, LinkCard, LinkCardList } from "@tvg/design-system/web";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { get } from "lodash";
import type { SeoTrack } from "@tvg/ts-types/Track";
import { useSelector } from "react-redux";
import { getIsLogged } from "@urp/store-selectors";
import SectionTitle from "../SectionTitle";
import type { Props } from "./types";
import FavoriteIcon from "../FavoriteIcon";
import { LINK_ITEM, LOCATION } from "../../utils/constants";
import { IconContainer } from "./styled-components";

const TracksInformationLocation = ({
  title,
  dictionary,
  onTrackClick,
  isFavorite,
  toggleFavorite
}: Props) => {
  const isLogged = useSelector(getIsLogged);
  const favoriteCount = get(dictionary, title, [])
    // @ts-ignore
    .reduce((acc: number, track: SeoTrack) => {
      if (isFavorite(track.code)) {
        // eslint-disable-next-line no-param-reassign
        return acc + 1;
      }
      return acc;
    }, 0);

  const tracksSection = useMemo(
    () => (
      <div key={title}>
        <SectionTitle>{title}</SectionTitle>
        <LinkCardList qaLabel={buildQaLabel([LINK_ITEM, LOCATION, title])}>
          {get(dictionary, title, []).map((track) => (
            <LinkCard
              isLinkItem
              key={track.code}
              qaLabel={track.name}
              title={track.name}
              startContent={
                <FavoriteIcon
                  isFavorite={isFavorite(track.code)}
                  toggleFavorite={() => toggleFavorite(track.code, track.name)}
                  trackName={track.name}
                />
              }
              endContent={
                <IconContainer>
                  <Icon
                    name="chevronRight"
                    size="s"
                    lineColor="--fd-colors-content-default"
                  />
                </IconContainer>
              }
              onPress={() => onTrackClick(track)}
            />
          ))}
        </LinkCardList>
      </div>
    ),
    [get(dictionary, title, []).length, favoriteCount, isLogged]
  );
  return tracksSection;
};

export default TracksInformationLocation;
