import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const VideoContainer = styled.div<{
  width: number | undefined;
  height: number | undefined;
  openInlineMobile?: boolean;
  isXsell?: boolean;
}>`
  position: relative;
  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
        `
      : ""}

  ${({ height }) =>
    height
      ? css`
          height: ${height}px;
        `
      : ""};

  ${({ isXsell, openInlineMobile }) =>
    openInlineMobile &&
    css`
      @media screen and ${breakpoints.tablet.max.sm} {
        position: fixed;
        top: ${isXsell ? "48px" : "var(--header-height, 106px)"};
        left: 0;
        right: 0;
        bottom: 0;
        height: 1000vh;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.7);
      }
    `};
`;

export const TitleContainer = styled.div<{ title: string | undefined }>`
  ${({ title }) => !title && "display: none;"}
  position: absolute;
  top: var(--space-space3, 12px);
  left: var(--space-space3, 12px);

  p {
    color: var(--fd-colors-content-on-dark);
  }
`;

export const CloseIconContainer = styled.div`
  padding: 0 0 10px 15px;
  position: absolute;
  top: var(--space-space3, 12px);
  right: var(--space-space3, 12px);
  cursor: pointer;

  path {
    stroke: var(--fd-colors-background-surface);
  }
`;

export const Loading = styled.div<{
  width?: number;
  height?: number;
  openInlineMobile?: boolean;
}>`
  display: flex;
  justify-content: center;
  background-color: var(--fd-colors-core-black-tint-090);
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

    ${({ openInlineMobile }) =>
    openInlineMobile &&
    css`
      @media screen and ${breakpoints.tablet.max.sm} {
        display: none;
      }
    `}
`;
